import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UsersApi from '../../services/users';
import {
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Typography,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment-timezone';

const steps = ['Dados', 'Serviço', 'Horários'];

const phoneMask = (value) => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};

const daysWeekly = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

const daysTercaSabado = ['Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

const daysSegundaSabado = [
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

const Agendar = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [data, setData] = useState(new Date());
  const [datas, setDatas] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [hours, setHours] = useState([]);
  const [hoursToSelect, setHoursToSelect] = useState([]);
  const [timezone, setTimezone] = useState('America/Sao_Paulo');
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [userToUpdate, setUserToUpdate] = useState();
  const [lancamentos, setLancamentos] = useState([]);
  const [dadoAgendado, setDadoAgendado] = useState();
  const [isNotValid, setIsNotValid] = useState(true);
  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [duracaoServico, setDuracaoServico] = useState();
  const [view, setView] = React.useState('');

  const nomesServicos = useMemo(
    () => servicosSelecionados?.map((service) => service.name).join(',') ?? '',
    [servicosSelecionados]
  );

  const precoTotalServicos = useMemo(
    () =>
      servicosSelecionados?.reduce(
        (accumulator, service) => accumulator + service.price,
        0
      ),
    [servicosSelecionados]
  );

  const idsServicosSelecionados = useMemo(
    () => servicosSelecionados?.map((service) => service.id).join(',') ?? '',
    [servicosSelecionados]
  );

  const loadData = async () => {
    try {
      const mail = searchParams.get('key');
      const response = await UsersApi.getByEmail(mail);
      const responsePremium = await UsersApi.getPremiumData(mail);
      setLoading(false);
      setServicos(JSON.parse(response[0].servicos));
      setAgendas(JSON.parse(response[0].agendas));
      const hoursParsed = JSON.parse(response[0].hours);
      setHours(hoursParsed);
      setClientes(JSON.parse(response[0].clientes));
      setTimezone(response[0].timezone);
      setUserToUpdate(response[0]);
      setLancamentos(JSON.parse(response[0].lancamentos));
      if (
        responsePremium[0].isPremium &&
        responsePremium[0]?.daysLeftPremium > 0
      ) {
        setIsNotValid(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const generateTimeSlots = () => {
    const slots = [];
    const interval = 30; // Intervalo em minutos

    // Filtra os horários disponíveis com `checked: true`
    const availableHours = hours.filter((x) => x.checked);

    availableHours.forEach((hour) => {
      const currentDate = moment(data).startOf('day'); // Data atual sem hora

      // Verifica se o dia da semana da data atual corresponde ao dia configurado
      const dayOfWeek = daysWeekly[currentDate.day()]; // Dia da semana da data selecionada
      if (
        (hour.day === 'Segunda à Sábado' &&
          daysSegundaSabado.includes(dayOfWeek)) ||
        (hour.day === 'Terça à Sábado' &&
          daysTercaSabado.includes(dayOfWeek)) ||
        hour.day === dayOfWeek
      ) {
        // Define o horário inicial e final com base nos dados do backend
        const startTime = currentDate.clone().set({
          hour: parseInt(hour.initialHour.split(':')[0], 10),
          minute: parseInt(hour.initialHour.split(':')[1], 10),
          second: 0,
        });

        const endTime = currentDate.clone().set({
          hour: parseInt(hour.finalHour.split(':')[0], 10),
          minute: parseInt(hour.finalHour.split(':')[1], 10),
          second: 0,
        });

        // Gera os slots no intervalo definido
        while (startTime.isSameOrBefore(endTime)) {
          slots.push(startTime.clone());
          startTime.add(interval, 'minutes');
        }
      }
    });

    return slots;
  };

  useEffect(() => {
    if (!hours.length) return;
    const dates = [];
    for (let i = 0; i < 60; i++) {
      const addDay = new Date();
      addDay.setDate(addDay.getDate() + i);

      // Filtra os horários disponíveis com `checked: true`
      const hoursUser = hours.filter((x) => x.checked);

      // Valida os dias da semana com base no campo `day`
      hoursUser.forEach((hour) => {
        if (hour.day === 'Segunda à Sábado') {
          if (daysSegundaSabado.includes(daysWeekly[addDay.getDay()])) {
            dates.push(addDay);
          }
        } else if (hour.day === 'Terça à Sábado') {
          if (daysTercaSabado.includes(daysWeekly[addDay.getDay()])) {
            dates.push(addDay);
          }
        } else {
          // Para dias específicos como "Sexta", valida diretamente
          if (hour.day === daysWeekly[addDay.getDay()]) {
            dates.push(addDay);
          }
        }
      });
    }

    if (dates.length) setData(dates[0]);
    setDatas(dates);
  }, [hours]);

  useEffect(() => {
    if (servicosSelecionados.length > 0) {
      const agendasNaData = agendas.filter((x) => {
        const agendaDate = moment(x.date).tz(timezone).format('YYYY-MM-DD');
        const targetDate = moment(data).tz(timezone).format('YYYY-MM-DD');
        return agendaDate === targetDate;
      });

      const concatenatedDuration = servicosSelecionados
        .map((service) => service.duration)
        .join('');

      const regex = /(\d+h)|(\d+min)/g;
      const matches = concatenatedDuration.match(regex);
      let totalMinutes = 0;

      if (matches) {
        matches.forEach((match) => {
          if (match.includes('h')) {
            totalMinutes += parseInt(match.replace('h', ''), 10) * 60;
          } else if (match.includes('min')) {
            totalMinutes += parseInt(match.replace('min', ''), 10);
          }
        });
      }

      setDuracaoServico(totalMinutes);

      const possibleSlots = generateTimeSlots();

      const isSlotAvailable = (slotStart) => {
        const slotEnd = moment(slotStart).add(totalMinutes, 'minutes');

        return !agendasNaData.some((agenda) => {
          const agendaStart = moment(agenda.hour);
          const agendaEnd = moment(agenda.hourEnd);

          return (
            (slotStart.isSameOrAfter(agendaStart) &&
              slotStart.isBefore(agendaEnd)) ||
            (slotEnd.isAfter(agendaStart) &&
              slotEnd.isSameOrBefore(agendaEnd)) ||
            (slotStart.isBefore(agendaStart) &&
              slotEnd.isSameOrAfter(agendaEnd))
          );
        });
      };

      const horariosLiberados = possibleSlots.filter(isSlotAvailable);

      setHoursToSelect(horariosLiberados);
    }
  }, [servicosSelecionados, data, timezone, hours]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const dataAgendar = JSON.parse(localStorage.getItem('@agendado'));
    if (dataAgendar) {
      if (new Date(dataAgendar.date) >= new Date()) {
        setDadoAgendado(dataAgendar);
        setActiveStep(3);
      }
    }
  }, []);

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  const handleChangeServico = (event, arrayServicosSelecionados) => {
    setServicosSelecionados(arrayServicosSelecionados);
  };

  const handlePhone = (value) => {
    setPhone(value);
    localStorage.setItem('phone', value);
  };

  const handleName = (value) => {
    setName(value);
    localStorage.setItem('name', value);
  };

  useEffect(() => {
    const nome = localStorage.getItem('name');
    const telefone = localStorage.getItem('phone');

    if (nome && telefone) {
      setName(nome);
      setPhone(telefone);
      setActiveStep(1);
    }
  }, []);

  const generateId = () => {
    const caracteres =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * caracteres.length);
      id += caracteres[randomIndex];
    }
    return id;
  };

  const handleDadosAgendamento = () => {
    const dataInit = hoursToSelect[view];
    const dataEnd = moment(dataInit).add(duracaoServico, 'minutes');
    const ok = window.confirm(
      'Confirmar agendamento? \n' +
        'Data: ' +
        moment(dataInit).format('DD/MM/YYYY - HH:mm') + // Corrigido para HH:mm
        '\n' +
        `${
          servicosSelecionados.length > 1
            ? 'Serviços: ' + nomesServicos
            : 'Serviço: ' + nomesServicos
        }`
    );
    if (!ok) return;
    const obj = {
      date: dataInit,
      hour: dataInit,
      hourEnd: dataEnd,
      service: nomesServicos,
    };

    const item = {
      category: nomesServicos,
      calendar: dataInit,
      value: precoTotalServicos,
      received: true,
      id: generateId(),
      formOfPayment: '',
    };

    const cleanPhone = (phone) =>
      phone.replace(/\D/g, '').replace('55', '').replace('+55', '');

    let responseClient = clientes
      .filter((x) => x.cellphone)
      .find(
        (x) =>
          cleanPhone(phone).includes(cleanPhone(x.cellphone)) ||
          cleanPhone(x.cellphone).includes(cleanPhone(phone))
      );
    const clientesAdd = JSON.parse(JSON.stringify(clientes));
    if (!responseClient) {
      responseClient = {
        id: generateId(),
        name: name,
        address: '',
        cellphone: phone,
        active: true,
      };

      clientesAdd.push(responseClient);
      setClientes(clientesAdd);
    }
    const agendamento = {
      date: dataInit,
      hour: dataInit,
      hourEnd: dataEnd,
      observation: '',
      title: '',
      releaseId: item.id,
      releaseData: item,
      serviceId: idsServicosSelecionados,
      clientId: responseClient?.id,
      repeat: 'unico',
      id: generateId(),
    };

    const agendasAdd = JSON.parse(JSON.stringify(agendas));
    agendasAdd.push(agendamento);

    const lancamentosAdd = JSON.parse(JSON.stringify(lancamentos));

    lancamentosAdd.push(item);

    const newUser = JSON.parse(JSON.stringify(userToUpdate));
    newUser.agendas = JSON.stringify(agendasAdd);
    newUser.clientes = JSON.stringify(clientesAdd);
    newUser.lancamentos = JSON.stringify(lancamentosAdd);

    UsersApi.postUsuario(newUser);

    setDadoAgendado(obj);

    localStorage.setItem('@agendado', JSON.stringify(obj));
    setActiveStep(3);

    const messages = [];
    messages.push({
      to: newUser.pushToken,
      title: 'Novo cliente agendado!',
      body: `Cliente: ${name} - ${moment(dataInit).format(
        'DD/MM/YYYY - HH:mm' // Corrigido para HH:mm
      )} \n ${nomesServicos}`,
    });

    UsersApi.sendNotifications(messages);
  };

  const step1 = () => {
    return (
      <>
        <h3 style={{ color: '#fff' }}>Preencha seus dados</h3>
        <TextField
          inputProps={{ maxLength: 30 }}
          InputLabelProps={{ style: { color: '#bababa' } }}
          value={name}
          onChange={(event) => handleName(event.target.value)}
          color="success"
          sx={{
            backgroundColor: '#1C1F1E',
            input: {
              '&::placeholder': {
                color: '#727272',
                opacity: 1,
              },
            },
            '& .MuiInputBase-root': {
              '& input': {
                color: '#fff',
              },
            },
          }}
          variant="outlined"
          style={{ marginTop: 10, color: '#fff' }}
          fullWidth
          id="outlined-basic"
          label="Nome"
        />
        <TextField
          inputProps={{ maxLength: 16 }}
          value={phoneMask(phone)}
          onChange={(event) => handlePhone(event.target.value)}
          InputLabelProps={{ style: { color: '#bababa' } }}
          color="success"
          sx={{
            backgroundColor: '#1C1F1E',
            input: {
              '&::placeholder': {
                color: '#727272',
                opacity: 1,
              },
            },
            '& .MuiInputBase-root': {
              '& input': {
                color: '#fff',
              },
            },
          }}
          variant="outlined"
          style={{ marginTop: 10 }}
          fullWidth
          id="outlined-basic"
          label="Celular"
        />
        <Button
          disabled={!name || !phone}
          sx={{
            color: '#fff',
            background: '#ff66c4',
            borderColor: '#ff66c4',
            fontWeight: 'bold',
            mr: 1,
            ':hover': { color: '#fff', background: '#ff66c4' },
          }}
          color="success"
          style={{ marginTop: 10 }}
          fullWidth
          onClick={() => setActiveStep(1)}
          variant="contained"
        >
          Continuar
        </Button>
      </>
    );
  };

  const step2 = () => {
    return (
      <div>
        <h3 style={{ color: '#fff' }}>Selecione o Serviço</h3>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ToggleButtonGroup
            orientation="vertical"
            value={servicosSelecionados}
            onChange={handleChangeServico}
          >
            {servicos
              .filter((x) => x.price > 0)
              .map((serv, index) => {
                return (
                  <ToggleButton
                    key={index}
                    style={{ marginTop: 10 }}
                    value={serv}
                    aria-label="list"
                  >
                    {serv.icon} {serv.name} -{' '}
                    {serv.price.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </ToggleButton>
                );
              })}
          </ToggleButtonGroup>
        </div>
        <Button
          disabled={servicosSelecionados.length < 1}
          sx={{
            color: '#fff',
            background: '#ff66c4',
            borderColor: '#ff66c4',
            fontWeight: 'bold',
            mr: 1,
            ':hover': { color: '#fff', background: '#ff66c4' },
          }}
          color="success"
          style={{ marginTop: 10 }}
          fullWidth
          onClick={() => setActiveStep(2)}
          variant="contained"
        >
          Continuar
        </Button>
        <Button
          sx={{
            color: '#ff66c4',
            borderColor: '#ff66c4',
            fontWeight: 'bold',
            mr: 1,
            ':hover': { color: '#fff', background: '#ff66c4' },
          }}
          style={{ marginTop: 10 }}
          fullWidth
          onClick={() => setActiveStep(0)}
          variant="outlined"
        >
          Voltar
        </Button>
      </div>
    );
  };

  const step3 = () => {
    return (
      <div>
        <h3 style={{ color: '#fff' }}>Selecione o Horário</h3>
        <FormControl fullWidth>
          <InputLabel
            id="demo-simple-select-label"
            style={{ color: '#ff66c4' }}
          >
            Data
          </InputLabel>
          <Select
            color="success"
            displayEmpty
            value={moment(data).format('DD/MM/YYYY')}
            label="Data"
            onChange={(e) => {
              setData(e.target.value);
            }}
            placeholder="test"
            renderValue={(selected) => {
              if (selected === undefined || selected === ' ') {
                return (
                  <Typography color={'#727272'}>Tipo selecionado</Typography>
                );
              }
              return selected;
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              backgroundColor: '#1C1F1E',
              color: '#fff',
              input: {
                color: '#727272',
                '&::placeholder': {
                  color: '#727272',
                  opacity: 1,
                },
              },
            }}
          >
            {datas.map((x, index) => {
              return (
                <MenuItem key={index} value={x}>{`
									${moment(x).format('DD/MM/YYYY')} - ${daysWeekly[x.getDay()]}`}</MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {!hoursToSelect?.length ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: '#fff',
              fontSize: 28,
              textAlign: 'center',
            }}
          >
            Não há horários disponíveis para o dia selecionado
          </div>
        ) : (
          hoursToSelect.map((x, index) => {
            if ((index + 1) % 2 === 0) {
              return (
                <div
                  key={index}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <ToggleButtonGroup
                    orientation="horizontal"
                    value={view}
                    exclusive
                    onChange={handleChange}
                  >
                    <ToggleButton
                      style={{ marginTop: 10 }}
                      value={index - 1}
                      aria-label="list"
                    >
                      {moment(hoursToSelect[index - 1]).format('HH:mm')}
                    </ToggleButton>
                    <ToggleButton
                      style={{ marginTop: 10, marginLeft: 10 }}
                      value={index}
                      aria-label="module"
                    >
                      {moment(x).format('HH:mm')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              );
            } else if (index === hoursToSelect.length - 1) {
              return (
                <div
                  key={index}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <ToggleButtonGroup
                    orientation="horizontal"
                    value={view}
                    exclusive
                    onChange={handleChange}
                  >
                    <ToggleButton
                      style={{ marginTop: 10, marginLeft: 10 }}
                      value={index}
                      aria-label="module"
                    >
                      {moment(x).format('HH:mm')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              );
            }
          })
        )}
        <Button
          disabled={!view && view !== 0}
          sx={{
            color: '#fff',
            background: '#ff66c4',
            borderColor: '#ff66c4',
            fontWeight: 'bold',
            mr: 1,
            ':hover': { color: '#fff', background: '#ff66c4' },
          }}
          color="success"
          style={{ marginTop: 10 }}
          fullWidth
          onClick={handleDadosAgendamento}
          variant="contained"
        >
          Continuar
        </Button>
        <Button
          sx={{
            color: '#ff66c4',
            borderColor: '#ff66c4',
            fontWeight: 'bold',
            mr: 1,
            ':hover': { color: '#fff', background: '#ff66c4' },
          }}
          style={{ marginTop: 10 }}
          fullWidth
          onClick={() => setActiveStep(1)}
          variant="outlined"
        >
          Voltar
        </Button>
      </div>
    );
  };

  const stepConcluded = () => {
    return (
      <div
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <div>
          <h3 style={{ color: '#fff' }}>Agendamento realizado com sucesso!</h3>
        </div>
        <div>
          <CheckCircleIcon style={{ fontSize: 100 }} />
        </div>
        <div>
          <h3 style={{ color: '#fff' }}>
            Horario: {moment(dadoAgendado.date).format('DD/MM/YYYY - hh:mm')}
          </h3>
          <h3 style={{ color: '#fff' }}>Serviço: {dadoAgendado.service}</h3>
        </div>
      </div>
    );
  };

  return (
    <div style={{ padding: 20 }}>
      {loading ? (
        <CircularProgress />
      ) : isNotValid ? (
        <>
          <h5 style={{ color: '#fff' }}>
            Link de agendamento online nao disponivel. Renovacao necessaria.
          </h5>
        </>
      ) : (
        <>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel style={{ color: '#fff !important' }}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {activeStep === 0
            ? step1()
            : activeStep === 1
            ? step2()
            : activeStep === 2
            ? step3()
            : stepConcluded()}
        </>
      )}
    </div>
  );
};

export default Agendar;
